module.exports = [{
      plugin: require('/Volumes/Nifty128/Sites/clients/dawn-fabrics/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":360},
    },{
      plugin: require('/Volumes/Nifty128/Sites/clients/dawn-fabrics/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-61372203-5","head":false},
    },{
      plugin: require('/Volumes/Nifty128/Sites/clients/dawn-fabrics/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
